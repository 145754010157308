import { Box, IconButton, Menu, MenuItem, Toolbar, AppBar as MuiAppBar, Avatar } from '@mui/material'
import { useState, MouseEvent } from 'react'
import { useAuth } from 'react-oidc-context'
import { Notifications } from '@common/components/Notifications'
import Logo from '/images/Logo.svg'

type AppBarProps = {
  userGroup: 'IAM' | 'IQM'
}

const GradientSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="378" height="64" viewBox="0 0 378 64" fill="none">
    <g filter="url(#filter0_f_24_1060)">
      <path
        d="M278 10.1664C278 143.175 78.5428 251 -167.5 251C-413.543 251 -613 143.175 -613 10.1664C-613 -122.842 -413.543 -136 -167.5 -136C78.5428 -136 278 -122.842 278 10.1664Z"
        fill="url(#paint0_linear_24_1060)"
        fillOpacity="0.8"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_24_1060"
        x="-713"
        y="-236"
        width="1091"
        height="587"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_24_1060" />
      </filter>
      <linearGradient
        id="paint0_linear_24_1060"
        x1="-478.5"
        y1="30.5"
        x2="88.1"
        y2="406.905"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#89D329" />
        <stop offset="1" stopColor="#087AA7" />
      </linearGradient>
    </defs>
  </svg>
)

export function AppBar({ userGroup }: AppBarProps) {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const auth = useAuth()

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <MuiAppBar position="static" sx={{ bgcolor: userGroup === 'IAM' ? 'white' : '#003140', boxShadow: 2 }}>
        <Box width="800" height="300" position="absolute">
          <GradientSVG />
        </Box>

        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box maxWidth="83px" zIndex={1}>
            <img src={Logo} alt="logo" />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Notifications userGroup={userGroup} />
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: 2 }}>
              <Avatar alt={auth.user?.profile.name} src="/static/images/avatar/1.jpg" sx={{ width: 32, height: 32 }} />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={() => void auth.signoutRedirect()}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </MuiAppBar>
    </Box>
  )
}
