import { matchPath } from 'react-router-dom'

export function getActive(path: string, pathname: string) {
  return path ? !!matchPath({ path: path, end: path === '/' }, pathname) : false
}

/**
 * Utility function to generate dynamic routes with parameters.
 * @param path - The route path, potentially containing parameters (e.g., '/studies/edit/:studyId')
 * @param params - An object containing the parameter values to replace in the path
 */
export function generatePathWithParams(path: string, params: Record<string, string | number>): string {
  return Object.keys(params).reduce((updatedPath, param) => {
    return updatedPath.replace(`:${param}`, String(params[param]))
  }, path)
}
