const FONT_PRIMARY = "'Bayer Sans', sans-serif"

export const typography = {
  fontFamily: FONT_PRIMARY,
  h1: {
    fontWeight: 300,
    lineHeight: 112 / 96,
    fontSize: 96,
    letterSpacing: -1.5,
  },
  h2: {
    fontWeight: 300,
    lineHeight: 72 / 60,
    fontSize: 60,
    letterSpacing: -0.5,
  },
  h3: {
    fontWeight: 700,
    lineHeight: 56 / 48,
    fontSize: 48,
  },
  h4: {
    fontWeight: 700,
    lineHeight: 42 / 32,
    fontSize: 34,
    letterSpacing: 0.25,
  },
  h5: {
    fontWeight: 700,
    lineHeight: 32 / 24,
    fontSize: 24,
  },
  h6: {
    fontWeight: 700,
    lineHeight: 32 / 20,
    fontSize: 20,
    letterSpacing: 0.15,
  },
  subtitle1: {
    fontWeight: 500,
    lineHeight: 28 / 16,
    fontSize: 16,
    letterSpacing: 0.15,
  },
  subtitle2: {
    fontWeight: 500,
    lineHeight: 22 / 14,
    fontSize: 14,
    letterSpacing: 0.1,
  },
  body1: {
    fontWeight: 400,
    lineHeight: 24 / 16,
    fontSize: 16,
    letterSpacing: 0.15,
  },
  body2: {
    fontWeight: 400,
    lineHeight: 20 / 14,
    fontSize: 14,
    letterSpacing: 0.17,
  },
  caption: {
    lineHeight: 20 / 12,
    fontSize: 12,
    letterSpacing: 0.4,
  },
  overline: {
    fontWeight: 400,
    lineHeight: 32 / 12,
    fontSize: 12,
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  button: {
    fontWeight: 700,
    letterSpacing: 0.4,
    lineHeight: 24 / 14,
    fontSize: 14,
    textTransform: 'none',
  },
} as const
