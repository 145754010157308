export const MuiPaperOverrides = () => ({
  MuiPaper: {
    defaultProps: {
      elevation: 1,
    },
    variants: [
      {
        props: { variant: 'padded' },
        style: {
          padding: '1.25rem',
        },
      },
    ],
  },
})
