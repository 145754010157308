import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { useAppNavigation } from '@common/hooks/useNavigation'
import { RouteKey } from 'src/routes'
import { SvgIconProps } from '@mui/material'

export type BreadcrumbItem = {
  title: string
  route_key: RouteKey
  icon?: React.ComponentType<SvgIconProps>
}

type BreadcrumbProps = {
  items: BreadcrumbItem[]
  separator?: React.ReactNode
  maxItems?: number
}

export function Breadcrumb({ items, separator, maxItems }: BreadcrumbProps) {
  const { getRoutePath } = useAppNavigation()
  return (
    <div>
      <Breadcrumbs maxItems={maxItems} aria-label="breadcrumb" separator={separator}>
        {items.map((item: BreadcrumbItem, index: number) => {
          const path = getRoutePath(item.route_key)
          if (!path) {
            console.error(`No path found for route key: ${item.route_key}`)
            return null
          }
          return (
            <Link key={index} color="inherit" href={path} sx={{ display: 'flex', alignItems: 'center' }}>
              {item.icon ? <item.icon sx={{ mr: 0.5 }} /> : ''}
              {item.title}
            </Link>
          )
        })}
      </Breadcrumbs>
    </div>
  )
}
