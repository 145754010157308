import { createContext } from 'react'

export type StepType = {
  label: string
  optional?: boolean
  completed?: boolean
}

interface StepperState {
  steps: StepType[]
  activeStep: number
}

export interface StepperContextType extends StepperState {
  setSteps: (steps: StepType[]) => void
  setActiveStep: (step: number) => void
  markStepCompleted: (stepIndex: number) => void
  markStepOptional: (stepIndex: number) => void
  resetSteps: () => void
}

export const StepperContext = createContext<StepperContextType | undefined>(undefined)
