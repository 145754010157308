import { alpha } from '@mui/material/styles'
import { Shadows } from '@mui/material/styles/shadows'

const createShadows = (): Shadows => {
  const baseColor1 = '#0E3F7E'
  const baseColor2 = '#2A3345'

  return [
    'none',
    `0px 8px 8px -4px ${alpha(baseColor1, 0.02)}, 0px 4px 4px -2px ${alpha(baseColor1, 0.02)}, 0px 2px 2px -1px ${alpha(baseColor2, 0.01)}, 0px 1px 1px -0.5px ${alpha(baseColor2, 0.01)}, 0px 0.5px 0.5px -0.25px ${alpha(baseColor2, 0.01)}, 0px 0px 0px 1px ${alpha(baseColor1, 0.01)}`,
    `0px 16px 16px -8px ${alpha(baseColor1, 0.03)}, 0px 8px 8px -4px ${alpha(baseColor1, 0.02)}, 0px 4px 4px -2px ${alpha(baseColor2, 0.02)}, 0px 2px 2px -1px ${alpha(baseColor2, 0.02)}, 0px 1px 1px -0.5px ${alpha(baseColor2, 0.02)}, 0px 0px 0px 1px ${alpha(baseColor1, 0.02)}`,
    `0px 24px 24px -12px ${alpha(baseColor1, 0.05)}, 0px 12px 12px -6px ${alpha(baseColor1, 0.02)}, 0px 6px 6px -3px ${alpha(baseColor2, 0.02)}, 0px 3px 3px -1.5px ${alpha(baseColor2, 0.02)}, 0px 1.5px 1.5px -0.75px ${alpha(baseColor2, 0.02)}, 0px 0px 0px 1px ${alpha(baseColor1, 0.02)}`,
    `0px 32px 32px -16px ${alpha(baseColor1, 0.06)}, 0px 16px 16px -8px ${alpha(baseColor1, 0.03)}, 0px 8px 8px -4px ${alpha(baseColor2, 0.03)}, 0px 4px 4px -2px ${alpha(baseColor2, 0.03)}, 0px 2px 2px -1px ${alpha(baseColor2, 0.03)}, 0px 0px 0px 1px ${alpha(baseColor1, 0.03)}`,
    `0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)`,
    `0px 24px 24px -12px ${alpha(baseColor1, 0.06)}, 0px 12px 12px -6px ${alpha(baseColor1, 0.03)}, 0px 6px 6px -3px ${alpha(baseColor2, 0.03)}, 0px 3px 3px -1.5px ${alpha(baseColor2, 0.03)}, 0px 1px 1px -0.5px ${alpha(baseColor2, 0.03)}, 0px 0px 0px 1px ${alpha(baseColor1, 0.03)}`,
    `0px 2px 16px 1px rgba(0, 0, 0, 0.12), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 4px 5px -2px rgba(0, 0, 0, 0.20)`,
    `0px 36px 36px -18px ${alpha(baseColor1, 0.06)}, 0px 18px 18px -9px ${alpha(baseColor1, 0.03)}, 0px 9px 9px -4.5px ${alpha(baseColor2, 0.06)}, 0px 4.5px 4.5px -2.25px ${alpha(baseColor2, 0.06)}, 0px 1.5px 1.5px -0.75px ${alpha(baseColor2, 0.06)}, 0px 0px 0px 1px ${alpha(baseColor1, 0.06)}`,
    // TODO: add another shadows to the list
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ]
}

export const shadows = createShadows()
