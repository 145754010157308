import { Chip } from '@mui/material'
import { TASK_STATE_COLOR, TASK_STATE_TEXT_COLOR } from './TaskStateColor'
import { QcTaskState } from '@common/config/api/client'
import { formatTaskStateText } from '../utils'

export default function TaskStateChip({ taskState }: { taskState: QcTaskState }) {
  return (
    <Chip
      data-testid="task-status-chip"
      label={formatTaskStateText(taskState)}
      sx={{
        backgroundColor: TASK_STATE_COLOR[taskState],
        color: TASK_STATE_TEXT_COLOR[taskState],
        padding: 0.5,
        alignItems: 'center',
      }}
    />
  )
}
