import ReaderTaskListTableTaskManager from './ReaderTaskListTableSkippedTasks'
import ReaderTaskListTableMyTasks from './ReaderTaskListTableMyTasks'
import ReaderTaskListTableTaskPool from './ReaderTaskListTableTaskPool'
import ReaderTaskListTableCompletedTasks from './ReaderTaskListTableCompletedTasks'
import EnhancedTabs from '@common/components/Tab/EnhancedTabs'
import { useUserGroup } from '@common/context/useUserGroup'
import { useEffect } from 'react'
import getRoutes from '@reader_task_list_view/utils'

export default function ReaderTaskListTabs() {
  const { setCurrentUserGroup, setNavigationRoutes } = useUserGroup()

  useEffect(() => {
    setCurrentUserGroup('IQM')
    setNavigationRoutes(getRoutes())
  }, [])

  const tabs = [
    { label: 'My tasks', content: <ReaderTaskListTableMyTasks /> },
    { label: 'Task pool', content: <ReaderTaskListTableTaskPool /> },
    {
      label: 'Skipped',
      content: <ReaderTaskListTableTaskManager />,
    },
    { label: 'Complete', content: <ReaderTaskListTableCompletedTasks /> },
  ]

  return <EnhancedTabs tabs={tabs} />
}
