import { getReadTasksReadTasksGet, GetReadTasksReadTasksGetData } from '@common/config/api/client'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const usePaginatedTasks = (queryKey: string[], paginationParams: GetReadTasksReadTasksGetData['query']) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      getReadTasksReadTasksGet({
        query: paginationParams,
      }),
    placeholderData: keepPreviousData,
  })
}
