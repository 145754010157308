import { QueryStatus } from '@common/config/api/client'

export const TASK_QUERY_COLOR: Record<QueryStatus, string> = {
  open: '#FFEFBF', // Gold for Open
  closed: '#DCE3E5', // Gray for Closed
  none: '#DCE3E5', // Gray for None
}

export const TASK_QUERY_TEXT_COLOR: Record<QueryStatus, string> = {
  open: '#546E7A', // Black for Open
  closed: '#546E7A', // Black for Closed
  none: '#546E7A', // Black for None
}
