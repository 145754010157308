import { getQcTasksQcTasksGet, GetQcTasksQcTasksGetData } from '@common/config/api/client'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const usePaginatedTasks = (queryKey: string[], paginationParams: GetQcTasksQcTasksGetData['query']) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      getQcTasksQcTasksGet({
        query: paginationParams,
      }),
    placeholderData: keepPreviousData,
  })
}
