import { Outlet } from 'react-router-dom'
import { AppBar } from '../AppBar'
import { SideBar, SIDEBAR_WIDTH } from '@common/components/SideBar'
import { styled } from '@mui/material/styles'
import { useUserGroup } from '@common/context/useUserGroup'
import StudyShell from '@common/components/StudyShell'

type MainStyleProps = {
  isSidebarVisible: boolean
}

export const MainStyled = styled('main', {
  shouldForwardProp: (prop) => prop !== 'isSidebarVisible',
})<MainStyleProps>(({ isSidebarVisible }) => ({
  flexGrow: 1,
  paddingTop: 32,
  paddingLeft: 40,
  paddingRight: 40,
  height: 'calc(100% - 64px)',
  width: `calc(100% - ${isSidebarVisible ? SIDEBAR_WIDTH : 0}px)`,
  marginLeft: isSidebarVisible ? SIDEBAR_WIDTH : 0,
}))

export default function DefaultLayout() {
  const { currentUserGroup, navigationRoutes } = useUserGroup()

  return (
    <>
      <SideBar userGroup={currentUserGroup} />
      <AppBar userGroup={currentUserGroup} />
      <MainStyled isSidebarVisible={currentUserGroup === 'IAM'}>
        <StudyShell routes={navigationRoutes} isStudyShellVisible={currentUserGroup === 'IQM'} />
        <Outlet />
      </MainStyled>
    </>
  )
}
