import { Theme } from '@mui/material/styles'
import { MuiLinkOverrides } from './components/Link'
import { CssBaselineOverrides } from './components/CssBaseline'
import { MuiPaperOverrides } from './components/Paper'
import { ButtonOverrides } from './components/Button'
import { InputOverrides } from './components/Input.ts'

export function componentsOverrides(theme: Theme) {
  return Object.assign(
    Object.assign(CssBaselineOverrides()),
    MuiLinkOverrides(),
    MuiPaperOverrides(),
    ButtonOverrides(theme),
    InputOverrides(theme),
  )
}
