export const getChipColorByStatus = (status: string | null | undefined) => {
  switch (status) {
    case 'LIVE':
      return 'success'
    case 'DRAFT':
      return 'secondary'
    case 'UAT':
      return 'warning'
    default:
      return 'default'
  }
}
