import { createContext } from 'react'
import { RouteConfig } from 'src/routes'

export type CurrentUserGroup = 'IAM' | 'IQM'

export type CurrentUserGroupContextType = {
  currentUserGroup: CurrentUserGroup
  navigationRoutes: RouteConfig[]
  setCurrentUserGroup: (userGroup: CurrentUserGroup) => void
  setNavigationRoutes: (routes: RouteConfig[]) => void
}

export const CurrentUserGroupContext = createContext<CurrentUserGroupContextType | undefined>(undefined)
