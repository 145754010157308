import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Paper } from '@mui/material'
import { EnhancedTabsProps } from './tabs.types'
import EnhancedTabPanel from './EnhancedTabPanel'
import { a11yProps } from './utils'
import { SyntheticEvent, useState } from 'react'

export default function EnhancedTabs({ tabs }: EnhancedTabsProps) {
  const [value, setValue] = useState(0)

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Paper
      sx={{
        width: '100%',
        padding: 3,
        borderRadius: 1,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            bgcolor: 'action.hover',
            borderRadius: 1.5,
            display: 'flex',
            justifyContent: 'center',
            padding: 0.5,
            gap: 0.5,
            typography: 'subtitle1',
          }}
        >
          <Tabs
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            value={value}
            onChange={handleChange}
            aria-label="enhanced tabs"
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                sx={{
                  borderRadius: 1,
                  bgcolor: value === index ? 'background.default' : 'action.hover',
                  color: 'text.primary',
                  typography: 'subtitle1',
                }}
                label={tab.label}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
      </Box>

      {tabs.map((tab, index) => (
        <EnhancedTabPanel key={index} value={value} index={index}>
          {tab.content}
        </EnhancedTabPanel>
      ))}
    </Paper>
  )
}
