import { SIDEBAR_WIDTH } from '@common/components/SideBar'
import { Box, Toolbar, AppBar, Stack, Typography, Button, CircularProgress } from '@mui/material'

type StudyHeaderProps = {
  isSidebarVisible: boolean
  handleSaveAsDraft: () => void
  isSubmitting: boolean
}

function StudyHeader({ isSidebarVisible, handleSaveAsDraft, isSubmitting }: StudyHeaderProps) {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        zIndex: 10,
        width: `calc(100% - ${isSidebarVisible ? SIDEBAR_WIDTH : 0}px)`,
      }}
    >
      <AppBar position="static" sx={{ bgcolor: 'white', boxShadow: 2 }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Stack>
            <Typography color="textPrimary" variant="subtitle2">
              Last save
            </Typography>
            <Typography color="textPrimary" variant="body2">
              29.08.2024
            </Typography>
          </Stack>
          <Button
            onClick={handleSaveAsDraft}
            color="secondary"
            variant="contained"
            size="large"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            {isSubmitting ? 'Saving...' : 'Save as draft'}
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default StudyHeader
