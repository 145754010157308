import { useQuery } from '@tanstack/react-query'
import { getStudyHomepageStudiesHomepageGet } from '@common/config/api/client'

export const STUDY_HOMEPAGE_LISTING_LIMIT = 10

export const useStudiesHomepage = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['studiesHomepage'],
    queryFn: () =>
      getStudyHomepageStudiesHomepageGet({
        query: {
          limit: STUDY_HOMEPAGE_LISTING_LIMIT,
        },
      }),
    select: ({ data }) => {
      return {
        studies: data?.items,
        metrics: data?.metrics,
      }
    },
  })

  const { studies = [], metrics } = data || {}

  return {
    studies,
    metrics,
    isLoading,
    isError,
    error,
  }
}
