import { Box, Drawer, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import CreateNewFolderRoundedIcon from '@mui/icons-material/CreateNewFolderRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import ChecklistRtlRoundedIcon from '@mui/icons-material/ChecklistRtlRounded'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded'
import Logo from '/images/Logo.svg'
import { ReactNode } from 'react'
import { ListItemButtonProps } from '@mui/material'
import { getActive } from '@common/utils/urlUtils'

type SideBarProps = {
  userGroup: 'IAM' | 'IQM'
}

export interface ListItemStyleProps extends ListItemButtonProps {
  active: boolean
}

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<ListItemStyleProps>(({ active, theme }) => ({
  position: 'relative',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  color: theme.palette.secondary.dark,
  borderRadius: theme.spacing(1),
  ...(active && {
    color: theme.palette.common.white,
    backgroundColor: '#002530',
  }),

  ':hover': {
    backgroundColor: alpha(theme.palette.action.hover, 0.08),
  },
}))

export const SIDEBAR_WIDTH = 328

export function SideBar({ userGroup }: SideBarProps) {
  const { pathname } = useLocation()

  const renderListItem = (label: string, path: string, icon: ReactNode) => {
    const active = getActive(path, pathname)

    return (
      <RouterLink to={path} style={{ textDecoration: 'none' }}>
        <ListItemStyle active={active}>
          <ListItemIcon sx={{ color: 'currentcolor', minWidth: '0', mr: 1 }}>{icon}</ListItemIcon>
          <ListItemText primary={label} />
        </ListItemStyle>
      </RouterLink>
    )
  }

  if (userGroup === 'IQM') {
    return null
  }

  return (
    <Drawer
      open
      variant="persistent"
      PaperProps={{
        sx: {
          overflowX: 'hidden',
          width: SIDEBAR_WIDTH,
          borderRightStyle: 'none',
          bgcolor: '#10384F',
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.standard,
            }),
        },
      }}
    >
      <Box
        sx={{
          width: '318px',
          height: '318px',
          position: 'absolute',
          left: '-184px',
          top: '-179px',
          borderRadius: '318px',
          background: 'linear-gradient(135deg, rgba(137, 211, 41, 0.80) 38.83%, rgba(8, 122, 167, 0.80) 83.51%)',
          filter: 'blur(35.08184814453125px)',
          zIndex: 0,
        }}
      />

      <Box position="relative" zIndex={1} mt={8}>
        <Box ml={3} mb={4}>
          <img src={Logo} alt="logo" />
        </Box>

        <List>
          {renderListItem('Study Overview', '/study_detail', <ContentPasteRoundedIcon />)}
          {renderListItem('Create New Clinical Study', '/create_study', <CreateNewFolderRoundedIcon />)}
          {renderListItem('User management', '/users', <PersonRoundedIcon />)}
          {renderListItem('QC Tasks', '/qc_tasks', <ChecklistRtlRoundedIcon />)}
          {renderListItem('Reader Tasks', '/reader_tasks', <ChecklistRtlRoundedIcon />)}
          {renderListItem('Queries', '/queries', <HelpRoundedIcon />)}
          {renderListItem('Reporting', '/reporting', <InsertDriveFileRoundedIcon />)}
        </List>
      </Box>
    </Drawer>
  )
}
