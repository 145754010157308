import { GridViewRounded, ViewListRounded } from '@mui/icons-material'
import { Divider, Stack, Typography } from '@mui/material'
import SegmentedControls from '../SegmentedControls/SegmentedControls'
type StudyDetailsHeaderProps = {
  activeControl: number
  setActiveControl: (val: number) => void
}
const StudyDetailsHeader = ({ activeControl, setActiveControl }: StudyDetailsHeaderProps) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="h4">Clinical Studies</Typography>
      <Stack direction="row">
        <SegmentedControls
          value={activeControl}
          setValue={setActiveControl}
          controls={[{ icon: <GridViewRounded /> }, { icon: <ViewListRounded /> }]}
        />
        <Divider />
      </Stack>
    </Stack>
  )
}

export default StudyDetailsHeader
