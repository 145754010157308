import { useEffect, useState } from 'react'
import { hasAuthParams, useAuth } from 'react-oidc-context'
import { ProtectedAppProps } from '../auth.types'

export function ProtectedApp(props: ProtectedAppProps) {
  const { children } = props

  const auth = useAuth()

  const [hasTriedSignin, setHasTriedSignin] = useState(false)

  useEffect(() => {
    if (!(hasAuthParams() || auth.isAuthenticated || auth.activeNavigator || auth.isLoading || hasTriedSignin)) {
      void auth.signinRedirect()
      setHasTriedSignin(true)
    }
  }, [auth, hasTriedSignin])

  return (
    <>
      {auth.error ? (
        <>
          <h1>We've hit a snag</h1>
        </>
      ) : auth.isLoading ? (
        <>
          <h1>Loading...</h1>
        </>
      ) : auth.isAuthenticated ? (
        <>{children}</>
      ) : (
        <>
          <h1>We've hit a snag</h1>
        </>
      )}
    </>
  )
}
