type EventCallback = (...args: unknown[]) => void

class EventEmitter {
  private events: { [key: string]: EventCallback[] } = {}

  on(event: string, listener: EventCallback) {
    if (!this.events[event]) {
      this.events[event] = []
    }
    this.events[event].push(listener)
  }

  off(event: string, listener: EventCallback) {
    if (!this.events[event]) return
    this.events[event] = this.events[event].filter((l) => l !== listener)
  }

  emit(event: string, ...args: unknown[]) {
    if (!this.events[event]) return
    this.events[event].forEach((listener) => listener(...args))
  }
}

const eventEmitter = new EventEmitter()
export default eventEmitter
