import { Route, Routes, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'
import { ProtectedComponent } from '@auth/components/ProtectedComponent'
import { Home } from '@common/components/Home'
import { StudyDetails } from '@study_setup/components'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { NotFound } from '@common/components/NotFound/NotFound'
import { ROUTES } from './routes'
import { Toaster } from 'sonner'
import DefaultLayout from '@common/components/layouts/DefaultLayout'
import setAxiosConfig from '@common/config/api/setAxiosConfig'
import QcTaskListTabs from '@qc_task_list_view/pages/QcTaskListTabs'
import CreateOrUpdateStudyLayout from '@study_setup/components/layouts/CreateOrUpdateStudyLayout'
import CreateOrUpdateStudy from '@study_setup/components/CreateOrUpdateStudy'
import ReaderTaskListTabs from './features/reader_task_list_view/pages/ReaderTaskListTabs'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^\//],
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

setAxiosConfig()

export default function App() {
  return (
    <>
      <Toaster />
      <SentryRoutes>
        <Route element={<DefaultLayout />}>
          <Route path={ROUTES.HOME.path} Component={Home} />
          <Route path={ROUTES.QC_TASKS.path} element={<QcTaskListTabs />} />
          <Route path={ROUTES.READER_TASKS.path} element={<ReaderTaskListTabs />} />
          <Route path={ROUTES.NOT_FOUND.path} element={<NotFound />} />
          <Route
            path={ROUTES.STUDY_DETAIL.path}
            element={
              <ProtectedComponent roles={['imaging_clinician']}>
                <StudyDetails />
              </ProtectedComponent>
            }
          />
        </Route>
        <Route element={<CreateOrUpdateStudyLayout />}>
          <Route
            path={ROUTES.CREATE_STUDY.path}
            element={
              <ProtectedComponent roles={['imaging_project_manager']}>
                <CreateOrUpdateStudy />
              </ProtectedComponent>
            }
          />
          <Route
            path={ROUTES.UPDATE_STUDY.path}
            element={
              <ProtectedComponent roles={['imaging_project_manager']}>
                <CreateOrUpdateStudy />
              </ProtectedComponent>
            }
          />
        </Route>
      </SentryRoutes>
    </>
  )
}
