import { QcTaskState } from '@common/config/api/client'

export const TASK_STATE_COLOR: Record<QcTaskState, string> = {
  NOT_ASSIGNED: '#DCE3E5',
  NOT_STARTED: '#C7EFFC',
  IN_PROGRESS: '#FFEFBF',
  COMPLETE: '#D7F5DB',
  REOPENED: '#FFEFBF',
  RESET: '#FFEFBF',
  RESET_PENDING_APPROVAL: '#FFEFBF',
  QC_FAILED: '#FEDADD',
  EXCLUDED: '#DCE3E5',
}

export const TASK_STATE_TEXT_COLOR: Record<QcTaskState, string> = {
  NOT_ASSIGNED: '#263238',
  NOT_STARTED: '#006080',
  IN_PROGRESS: '#994300',
  COMPLETE: '#1B5E24',
  REOPENED: '#994300',
  RESET: '#994300',
  RESET_PENDING_APPROVAL: '#994300',
  QC_FAILED: '#910410',
  EXCLUDED: '#263238',
}
