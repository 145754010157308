import { useState } from 'react'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import { Badge, Box, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material'

const NOTIFICATIONS_COUNT = 0

type NotificationsProps = {
  userGroup: 'IAM' | 'IQM'
}

export function Notifications({ userGroup }: NotificationsProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const theme = useTheme()

  const renderHead = (
    <Stack>
      <Typography p={2}>Notifications</Typography>
      <Divider />
    </Stack>
  )

  return (
    <>
      <Box
        data-testid="notifications-box"
        sx={{ color: userGroup === 'IAM' ? theme.palette.action.active : theme.palette.action.disabled }}
        display="inline"
      >
        <IconButton color="inherit" onClick={() => setIsOpen(true)} size="large">
          <Badge badgeContent={NOTIFICATIONS_COUNT} color="error">
            <NotificationsRoundedIcon />
          </Badge>
        </IconButton>
      </Box>

      <Drawer
        anchor="right"
        onClose={() => setIsOpen(false)}
        open={isOpen}
        PaperProps={{ sx: { maxWidth: 420, width: 1 }, elevation: 2 }}
        slotProps={{ backdrop: { invisible: true } }}
      >
        {renderHead}
      </Drawer>
    </>
  )
}
