import { StepType } from './context'

export enum StepperActionType {
  SET_STEPS = 'SET_STEPS',
  SET_ACTIVE_STEP = 'SET_ACTIVE_STEP',
  MARK_STEP_COMPLETED = 'MARK_STEP_COMPLETED',
  MARK_STEP_OPTIONAL = 'MARK_STEP_OPTIONAL',
  RESET_STEPS = 'RESET_STEPS',
}

export type StepperAction =
  | { type: StepperActionType.SET_STEPS; payload: StepType[] }
  | { type: StepperActionType.SET_ACTIVE_STEP; payload: number }
  | { type: StepperActionType.MARK_STEP_COMPLETED; payload: number }
  | { type: StepperActionType.MARK_STEP_OPTIONAL; payload: number }
  | { type: StepperActionType.RESET_STEPS }

export const initialStepperState = {
  steps: [] as StepType[],
  activeStep: 0,
}

export const stepperReducer = (state: typeof initialStepperState, action: StepperAction) => {
  switch (action.type) {
    case StepperActionType.SET_STEPS:
      return { ...state, steps: action.payload }
    case StepperActionType.SET_ACTIVE_STEP:
      return { ...state, activeStep: action.payload }
    case StepperActionType.MARK_STEP_COMPLETED:
      return {
        ...state,
        steps: state.steps.map((step, index) => (index === action.payload ? { ...step, completed: true } : step)),
      }
    case StepperActionType.MARK_STEP_OPTIONAL:
      return {
        ...state,
        steps: state.steps.map((step, index) => (index === action.payload ? { ...step, optional: true } : step)),
      }
    case StepperActionType.RESET_STEPS:
      return initialStepperState
    default:
      return state
  }
}
