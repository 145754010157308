import { useState, MouseEvent } from 'react'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import EnhancedTableBodyTaskPool from '@reader_task_list_view/components/EnhancedTableBodyTaskPool'
import { HeadCell, Order } from '@common/components/Table/table.types'
import { GetReadTasksReadTasksGetData, ReadTask } from '@common/config/api/client'
import { usePaginatedTasks } from '@reader_task_list_view/hooks/usePaginatedTasks'
import { DEFAULT_STUDY } from '@common/constants/study'
import { ROW_PER_PAGE_OPTIONS } from '@common/constants/table'

const headCells: readonly HeadCell<ReadTask>[] = [
  { id: 'task_id', disablePadding: true, label: 'Task code' },
  { id: 'blind_code', disablePadding: false, label: 'Blind code' },
  { id: 'task_type', disablePadding: false, label: 'Type' },
  { id: 'priority', disablePadding: false, label: 'Priority' },
  { id: 'created_at', disablePadding: false, label: 'Date created' },
  { id: 'due_date', disablePadding: false, label: 'Due date' },
  { id: 'status', disablePadding: false, label: 'Status' },
]

export default function ReaderTaskListTableTaskPool() {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof ReadTask | string>('task_id')
  const [page, setPage] = useState(0)

  const [paginationParams, setPaginationParams] = useState<GetReadTasksReadTasksGetData['query']>({
    limit: ROW_PER_PAGE_OPTIONS[2],
    next_cursor: null,
    previous_cursor: null,
    category: 'task_pool',
    study_id: DEFAULT_STUDY.study_id,
    asc_order: true,
    order_by_field: 'created_at',
  })

  const { data, error, isLoading } = usePaginatedTasks(
    ['ReadTasksList', 'TaskPool', `${page}`, `${paginationParams.limit}`],
    paginationParams,
  )

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error: {error.message}</div>
  }

  const handleRequestSort = (_event: MouseEvent<unknown>, property: keyof ReadTask) => {
    const isAsc = orderBy.split('.')[0] === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage > 0) {
      // Moving to next page
      setPaginationParams((prev) => ({
        ...prev,
        next_cursor: data?.data?.pagination.next_cursor,
        previous_cursor: null,
      }))
      setPage(page + 1)
    } else if (newPage < 0) {
      // Moving to previous page
      setPaginationParams((prev) => ({
        ...prev,
        next_cursor: null,
        previous_cursor: data?.data?.pagination.previous_cursor,
      }))
      setPage(page - 1)
    }
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  return (
    <EnhancedTable<ReadTask>
      rows={data?.data?.items || []}
      rowCount={-1}
      headCells={headCells}
      order={order}
      orderBy={orderBy.split('.')[0] as keyof ReadTask}
      page={page}
      rowsPerPage={paginationParams.limit || 0}
      rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      next_cursor={data?.data?.pagination.next_cursor}
      previous_cursor={data?.data?.pagination.previous_cursor}
      onRequestSort={handleRequestSort}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleRowsPerPageChange}
    >
      <EnhancedTableBodyTaskPool rows={data?.data?.items || []} order={order} orderBy={orderBy} />
    </EnhancedTable>
  )
}
