import { useContext } from 'react'
import { StepperContext } from './context'

export const useStepper = () => {
  const context = useContext(StepperContext)
  if (context === undefined) {
    throw new Error('useStepper must be used within a StepperProvider')
  }
  return context
}
