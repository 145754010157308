import { Breadcrumb } from '@common/components/Breadcrumb'
import { Box, Chip, Typography } from '@mui/material'
import { RouteKeyEnum } from 'src/routes'
import EnhancedNavTabs from '@common/components/Tab/EnhancedNavTabs'
import { StudyShellProps } from './study.types'
import { DEFAULT_STUDY } from '@common/constants/study'

export default function StudyShell({ routes, isStudyShellVisible }: StudyShellProps) {
  const hasQCTasks = routes.find((route) => route.path === '/qc_tasks') !== undefined
  const BreadcrumbItems = [
    {
      title: 'Home',
      route_key: RouteKeyEnum.HOME,
    },
    {
      title: 'Anotate',
      route_key: hasQCTasks ? RouteKeyEnum.QC_TASKS : RouteKeyEnum.READER_TASKS,
    },
  ]

  if (!isStudyShellVisible) {
    return null
  }

  return (
    <Box
      data-testid="study-shell"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        paddingBottom: 3,
      }}
    >
      <Breadcrumb items={BreadcrumbItems} />
      {hasQCTasks ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            gap: 1.5,
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: 'text.primary',
            }}
          >
            Anotate
          </Typography>
          <Chip
            label="Live"
            sx={{
              backgroundColor: '#B9F6CA',
              color: '#1B5E24',
              padding: 0.5,
              alignItems: 'center',
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            gap: 1.5,
            alignItems: 'start',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: 'text.primary',
            }}
          >
            {DEFAULT_STUDY.study_name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
            }}
          >
            ID {DEFAULT_STUDY.study_pseudo_id}
          </Typography>
        </Box>
      )}

      <EnhancedNavTabs routes={routes} />
    </Box>
  )
}
