import { Box, Stepper, Step, StepLabel, Typography, useTheme, Tooltip, useMediaQuery } from '@mui/material'
import { StepType } from '@study_setup/contexts/stepper/context'

type HorizontalLinearStepperProps = {
  steps: StepType[]
  activeStep: number
  onStepClick: (stepIndex: number) => void
}

export function HorizontalLinearStepper({ steps, activeStep, onStepClick }: HorizontalLinearStepperProps) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'))

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map(({ label, optional = false, completed = false }, index) => {
          const isActiveStep = index === activeStep
          const stepProps: { completed: boolean } = { completed }
          const labelProps: {
            optional?: React.ReactNode
            slotProps?: {
              label?: {
                sx?: object
              }
            }
          } = {}

          if (optional) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>
          }

          labelProps.slotProps = {
            label: {
              sx: {
                display: 'block',
                [theme.breakpoints.down('xl')]: {
                  display: isActiveStep ? 'block' : 'none',
                },
              },
            },
          }

          const stepLabel = <StepLabel {...labelProps}>{label}</StepLabel>

          return (
            <Step
              key={label}
              {...stepProps}
              onClick={() => {
                onStepClick(index)
              }}
            >
              {!isActiveStep && isSmallScreen ? (
                <Tooltip title={label} arrow>
                  <span>{stepLabel}</span>
                </Tooltip>
              ) : (
                stepLabel
              )}
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}
