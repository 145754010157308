import { StudyMetrics, StudyPhaseMetrics } from '@common/config/api/client'
import { capitalize } from '@common/utils/stringUtils'
import { Paper, Stack, Typography } from '@mui/material'

type StudyDetailsMetricsProps = {
  metrics: StudyMetrics | undefined
}
const StudyDetailsMetrics = ({ metrics }: StudyDetailsMetricsProps) => {
  return (
    <Stack direction="row" spacing={2}>
      <Paper sx={{ padding: 3, flex: 1 }}>
        <Typography variant="subtitle1" sx={{ marginBottom: 4 }}>
          Total studies
        </Typography>
        <Stack direction="row" spacing={4}>
          <Stack>
            <Typography variant="h3">{metrics?.total}</Typography>
            <Typography variant="body2" color="textSecondary">
              Total studies
            </Typography>
          </Stack>
        </Stack>
      </Paper>
      <Paper sx={{ padding: 3, flex: 1 }}>
        <Typography variant="subtitle1" sx={{ marginBottom: 4 }}>
          Status of the studies
        </Typography>
        <Stack direction="row" spacing={4}>
          <Stack>
            <Typography variant="h3">{metrics?.completed}</Typography>
            <Typography variant="body2" color="textSecondary">
              Completed
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h3">{metrics?.ongoing}</Typography>
            <Typography variant="body2" color="textSecondary">
              Ongoing
            </Typography>
          </Stack>
        </Stack>
      </Paper>
      <Paper sx={{ padding: 3, flex: 1 }}>
        <Typography variant="subtitle1" sx={{ marginBottom: 4 }}>
          Studies in each phase
        </Typography>
        <Stack direction="row" spacing={4}>
          {metrics?.phases?.map(({ phase, total }: StudyPhaseMetrics, index) => (
            <Stack key={index}>
              <Typography variant="h3">{total}</Typography>
              <Typography variant="body2" color="textSecondary">
                {capitalize(phase.name)}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Paper>
    </Stack>
  )
}

export default StudyDetailsMetrics
