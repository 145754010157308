import { useMemo } from 'react'
import { TableBody, TableRow, TableCell, Typography } from '@mui/material'
import { EnhancedTableBodyProps } from '@reader_task_list_view/reader_task_list.types'
import { formatTaskCreationDate, getComparator } from '@reader_task_list_view/utils'
import TaskStateChip from './TaskStateChip'
import TaskTypeChip from './TaskTypeChip'

export default function EnhancedTableBodyTaskPool({ rows, order, orderBy }: EnhancedTableBodyProps) {
  const visibleRows = useMemo(() => [...rows].sort(getComparator(order, orderBy)), [order, orderBy, rows])

  return (
    <TableBody>
      {visibleRows.map((row) => {
        return (
          <TableRow
            tabIndex={-1}
            key={row.task_id}
            sx={{
              borderBottom: '1px solid rgba(220, 227, 229, 1)',
            }}
          >
            <TableCell align="left" data-testid="task-pseudo-id-cell">
              {row.task_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="blind-code-cell">
              {row.blind_code}
            </TableCell>
            <TableCell align="left" data-testid="task-type-cell">
              <TaskTypeChip taskType={row.task_type} />
            </TableCell>
            <TableCell align="left" data-testid="priority-cell">
              {row.priority}
            </TableCell>
            <TableCell align="left" data-testid="created-at-cell">
              {formatTaskCreationDate(row.created_at)}
            </TableCell>
            <TableCell align="left" data-testid="due-date-cell">
              {formatTaskCreationDate(row.due_date)}
            </TableCell>
            <TableCell align="left" data-testid="status-cell">
              <TaskStateChip taskState={row.status} />
            </TableCell>
            <TableCell align="right" data-testid="assign-cell">
              <Typography
                sx={{
                  cursor: 'pointer',
                  color: 'secondary.contrastText',
                  typography: 'button',
                }}
              >
                Assign and start
              </Typography>
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}
