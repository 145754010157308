import { BASE_API_URL, SESSION_STORAGE_KEY } from '@common/constants/api'
import { client } from './client'
import qs from 'qs'

export default function setAxiosConfig() {
  client.setConfig({
    baseURL: BASE_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })

  client.instance.interceptors.request.use(
    (config) => {
      const userData = sessionStorage.getItem(SESSION_STORAGE_KEY)
      if (userData) {
        const user = JSON.parse(userData)
        const access_token = user['access_token']

        config.headers.Authorization = `Bearer ${access_token}`
      }

      return config
    },
    (error) => Promise.reject(error),
  )
}
