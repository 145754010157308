import { Order } from '@common/components/Table/table.types'
import { ReadTask } from '@common/config/api/client'
import { RouteConfig, RouteKeyEnum, ROUTES } from 'src/routes'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNestedValue = (obj: Record<string, any>, path: string) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj)
}

export const getComparator = (order: Order, orderBy: keyof ReadTask | string) => {
  return order === 'desc'
    ? (a: ReadTask, b: ReadTask) => {
        const aValue = getNestedValue(a, orderBy)
        const bValue = getNestedValue(b, orderBy)

        if (a === null || a === undefined) {
          return 1
        } else if (b === null || b === undefined) {
          return -1
        }
        return bValue < aValue ? -1 : 1
      }
    : (a: ReadTask, b: ReadTask) => {
        const aValue = getNestedValue(a, orderBy)
        const bValue = getNestedValue(b, orderBy)

        if (aValue === null || aValue === undefined) {
          return -1
        } else if (bValue === null || bValue === undefined) {
          return 1
        }
        return aValue < bValue ? -1 : 1
      }
}

export const formatTaskTypeText = (taskType: string) => {
  const type = taskType.replace('_', ' ')
  return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
}

export const formatTaskStateText = (taskState: string) => {
  const state = taskState.replace('_', ' ')
  return state.charAt(0).toUpperCase() + state.slice(1).toLowerCase()
}

export const formatTaskCreationDate = (date: string) => {
  return date.split('T')[0].split('-').reverse().join('/')
}

export const calculateTimeSpent = (startTime?: string, endTime?: string) => {
  if (!startTime || !endTime) {
    return 0
  }
  const start = new Date(startTime)
  const end = new Date(endTime)
  const timeDiff = Math.abs(end.getTime() - start.getTime())
  const timeSpent = Math.ceil(timeDiff / (1000 * 60))

  const days = Math.floor(timeSpent / (60 * 24))
  const hours = Math.floor((timeSpent % (60 * 24)) / 60)
  return `${days}d ${hours}h`
}

export default function getRoutes(): RouteConfig[] {
  const included_routes = [RouteKeyEnum.READER_TASKS, RouteKeyEnum.READER_DOCUMENTS]

  const routes = Object.values(RouteKeyEnum).filter((route) => included_routes.includes(route))
  return routes.map((route) => {
    return {
      ...ROUTES[route],
      label: ROUTES[route].label.replace('Reader ', ''),
    }
  })
}
