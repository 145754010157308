import { useMemo } from 'react'
import { ExtendedUser, ProtectedComponentProps } from '../auth.types'
import { useExtendedAuth } from '../hooks/useExtendedAuth'

export function ProtectedComponent(props: ProtectedComponentProps) {
  const hasRequiredRoles = useMemo(() => {
    return (user: ExtendedUser | undefined, roles: string[]) => {
      const safeRoles = roles ?? []
      if (!user?.profile?.realm_roles) {
        return false
      }
      return safeRoles.some((role) => user.profile.realm_roles?.includes(role))
    }
  }, [])

  const { children, roles } = props
  const { user } = useExtendedAuth()

  return (
    <>
      {hasRequiredRoles(user, roles) ? (
        children
      ) : (
        <div>
          <h1>you dont have any of these roles </h1>
          <h2> {roles.join(', ')} required</h2>
        </div>
      )}
    </>
  )
}
