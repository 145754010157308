import { Key, MouseEvent } from 'react'
import { TableHead, TableRow, TableCell, Checkbox, TableSortLabel, Box } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { EnhancedTableHeadProps } from './table.types'

const EnhancedTableHead = <T,>({
  headCells,
  numSelected,
  onSelectAllClick,
  order,
  orderBy,
  rowCount,
  onRequestSort,
}: EnhancedTableHeadProps<T>) => {
  const createSortHandler = (property: keyof T) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead data-testid="table-head">
      <TableRow>
        {numSelected !== undefined && onSelectAllClick !== undefined && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all tasks',
              }}
            />
          </TableCell>
        )}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id as Key}
            sortDirection={orderBy === headCell.id ? order : false}
            data-testid="head-table-cell"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
