import { ListStudy } from '@common/config/api/client'
import { capitalize } from '@common/utils/stringUtils'
import { getChipColorByStatus } from '@common/utils/styleUtils'
import { generatePathWithParams } from '@common/utils/urlUtils'
import { Box, Chip, Paper, Stack, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/routes'

type StudyDetailsCardProps = {
  item: ListStudy
}

type Phase = {
  id: string
  name: string
}

const StudyDetailsCard = ({ item }: StudyDetailsCardProps) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const handleCardClick = () => {
    const path = generatePathWithParams(ROUTES.UPDATE_STUDY.path, { studyId: item.id })
    navigate(path)
  }

  return (
    <Paper sx={{ padding: 3, width: '100%', cursor: 'pointer' }} onClick={handleCardClick}>
      <Box sx={{ marginBottom: 6 }}>
        <Stack direction="row" spacing={1} sx={{ marginBottom: 2 }}>
          <Chip
            size="small"
            color={getChipColorByStatus(item.status)}
            variant="filled"
            label={capitalize(item.status)}
          />
          {item.phases?.map((phase: Phase, index) => (
            <Chip size="small" color="secondary" variant="filled" label={capitalize(phase.name)} key={index} />
          ))}
        </Stack>
        <Typography variant="h5">{item.nickname}</Typography>
      </Box>
      <Stack spacing={2}>
        <Stack spacing={0.5}>
          <Typography variant="caption">Study Type</Typography>
          <Typography variant="body1">{item.type ?? 'None'}</Typography>
        </Stack>
        <Stack spacing={0.5}>
          <Typography variant="caption">Image Modalities</Typography>
          <Typography variant="body1">
            {item.imaging_modalities?.map((modality) => modality.name).join(', ')}
          </Typography>
        </Stack>
        <Stack spacing={0.5}>
          <Typography variant="caption">Progress</Typography>
          <Stack direction="row" gap={0.5} flexWrap="wrap">
            <Chip size="small" color="error" variant="outlined" label={`${item.overdue_count} Overdue`} />
            <Chip
              size="small"
              sx={{ color: theme.palette.text.primary, borderColor: theme.palette.secondary.dark }}
              variant="outlined"
              label={`${item.queries_count} Queries`}
            />
            <Chip
              size="small"
              sx={{ color: theme.palette.text.primary, borderColor: theme.palette.secondary.dark }}
              variant="outlined"
              label={`${item.open_tasks_count} open tasks`}
            />
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default StudyDetailsCard
