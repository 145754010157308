import { Breadcrumb } from '@common/components/Breadcrumb'
import { Home } from '@mui/icons-material'
import { RouteKeyEnum } from 'src/routes'
import { Stack, Grid2 as Grid, Typography } from '@mui/material'
import StudyDetailsCards from '@study_setup/components/StudyDetails/StudyDetailsCards'
import StudyDetailsHeader from '@study_setup/components/StudyDetails/StudyDetailsHeader'
import { useMemo, useState } from 'react'
import { useStudiesHomepage } from '@study_setup/hooks/useStudiesHomepage'
import StudyDetailsMetrics from './StudyDetailsMetrics/StudyDetailsMetrics'

export function StudyDetails() {
  const BreadcrumbItems = [
    {
      title: 'Home',
      route_key: RouteKeyEnum.HOME,
      icon: Home,
    },
    {
      title: 'Study Oveview',
      route_key: RouteKeyEnum.STUDY_DETAIL,
    },
  ]
  const [activeControl, setActiveControl] = useState(0)
  const layout = useMemo(() => (activeControl == 0 ? 'grid' : 'list'), [activeControl])
  const { studies, metrics } = useStudiesHomepage()
  return (
    <>
      <Breadcrumb items={BreadcrumbItems} />
      <Grid container columnSpacing={3} sx={{ marginTop: 4 }}>
        <Grid size={12}>
          <Stack spacing={8}>
            <Stack spacing={4}>
              <Typography variant="h4">Studies Overview</Typography>
              <StudyDetailsMetrics metrics={metrics} />
            </Stack>
            <Stack spacing={4}>
              <StudyDetailsHeader activeControl={activeControl} setActiveControl={setActiveControl} />
              <StudyDetailsCards items={studies} layout={layout} />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}
