import SearchIcon from '@mui/icons-material/Search'
import { Avatar, Box, InputAdornment, Menu, MenuItem, MenuList, TextField, Typography } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Fragment } from 'react/jsx-runtime'
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded'
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded'
import { useState } from 'react'
import { User } from '@common/config/api/client'

export default function PopupAssignMenuList({
  assignedTo,
  assigneeList,
}: {
  assignedTo?: string | null
  assigneeList: User[]
}) {
  const [query, setQuery] = useState<string>('')
  const [results, setResults] = useState<User[]>(assigneeList)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    setQuery(input)

    if (input) {
      setResults(assigneeList.filter((item) => item.user_name?.toLowerCase().includes(input.toLowerCase())))
    } else {
      setResults(assigneeList)
    }
  }

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <Fragment>
          <Box {...bindTrigger(popupState)}>
            <Typography
              sx={{
                color: assignedTo ? 'inherit' : '#006B8C',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: assignedTo ? 'underline' : 'none',
                },
              }}
              data-testid="assign-task"
            >
              {assignedTo ? assignedTo : 'Assign task'}
            </Typography>
          </Box>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            data-testid="popup-menu-list"
          >
            <MenuList
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                borderRadius: 1,
                boxShadow: 4,
              }}
            >
              <MenuItem
                sx={{
                  width: 400,
                  paddingBottom: 1,
                }}
                onKeyDown={(e) => e.stopPropagation()}
                data-testid="popup-menu-list-item-search"
              >
                <TextField
                  variant="outlined"
                  size="small"
                  onChange={handleSearch}
                  value={query}
                  label="Search"
                  fullWidth
                  sx={{
                    boxShadow: 1,
                  }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </MenuItem>
              <MenuItem
                onClick={popupState.close}
                sx={{
                  width: '100%',
                }}
              >
                {assignedTo ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      padding: 1,
                    }}
                    data-testid="unassign-task"
                  >
                    <PersonRemoveRoundedIcon
                      sx={{
                        marginRight: 1.5,
                        width: 24,
                        height: 24,
                        color: 'text.secondary',
                      }}
                    />
                    <Typography>Unassign</Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      padding: 1,
                    }}
                    data-testid="assign-to-me"
                  >
                    <PersonAddRoundedIcon
                      sx={{
                        marginRight: 1.5,
                        width: 24,
                        height: 24,
                        color: 'text.secondary',
                      }}
                    />
                    <Typography>Assign to me</Typography>
                  </Box>
                )}
              </MenuItem>
              {results.map((assignee) => (
                <MenuItem
                  onClick={popupState.close}
                  key={assignee.user_id}
                  data-testid="assign-to-user"
                  sx={{
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      padding: 1,
                    }}
                  >
                    <Avatar sx={{ marginRight: 1.5, width: 24, height: 24 }}>
                      <Typography variant="overline" color="text.primary">
                        {assignee.user_name
                          ?.split(' ')
                          .map((word) => word[0])
                          .join('')}
                      </Typography>
                    </Avatar>
                    <Typography variant="body1">{assignee.user_name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </PopupState>
  )
}
