import { useUserGroup } from '@common/context/useUserGroup'
import { Stack, Typography, Grid2, Paper } from '@mui/material'
import { useEffect } from 'react'

const HEADER_CARDS = ['KPI card', 'KPI card', 'KPI card']
const STUDY_CARDS = ['Study card', 'Study card', 'Study card', 'Study card', 'Study card', 'Study card']

export function Home() {
  const { setCurrentUserGroup } = useUserGroup()

  useEffect(() => {
    setCurrentUserGroup('IAM')
  })

  return (
    <Stack gap={3}>
      <Grid2 container columnSpacing={3}>
        {HEADER_CARDS.map((item, index) => (
          <Grid2 key={index} size={4}>
            <Paper
              elevation={2}
              sx={{
                bgcolor: 'rgba(2, 115, 154, 0.20)',
                p: 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 170,
                borderRadius: 2,
              }}
            >
              <Typography variant="h5">{item}</Typography>
            </Paper>
          </Grid2>
        ))}
      </Grid2>
      <Typography variant="h4">Studies Overview</Typography>
      <Typography variant="h5">Your Studies</Typography>
      <Grid2 container spacing={3}>
        {STUDY_CARDS.map((item, index) => (
          <Grid2 key={index} size={6}>
            <Paper
              elevation={2}
              sx={{
                p: 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 215,
                borderRadius: 2,
              }}
            >
              <Typography variant="h5">{item}</Typography>
            </Paper>
          </Grid2>
        ))}
      </Grid2>
    </Stack>
  )
}
