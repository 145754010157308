import { Menu, MenuItem, MenuList, Typography } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Fragment } from 'react/jsx-runtime'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'

export default function PopupMenuList() {
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <Fragment>
          <MoreVertRoundedIcon
            fontSize="small"
            {...bindTrigger(popupState)}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                borderRadius: '50%',
                backgroundColor: '#F3F4F6',
              },
            }}
            data-testid="popup-menu-icon"
          />
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            data-testid="popup-menu-list"
          >
            <MenuList
              sx={{
                width: 220,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                borderRadius: 1,
                boxShadow: 4,
              }}
            >
              <MenuItem
                sx={{
                  width: '100%',
                }}
                onClick={popupState.close}
                data-testid="popup-menu-list-item-reopen"
              >
                <Typography variant="body1">Reopen</Typography>
              </MenuItem>
              <MenuItem
                sx={{
                  width: '100%',
                }}
                onClick={popupState.close}
                data-testid="popup-menu-list-item-reset"
              >
                <Typography variant="body1">Reset</Typography>
              </MenuItem>
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </PopupState>
  )
}
