import { useAuth } from 'react-oidc-context'
import { ExtendedAuth } from '../auth.types'

/**

Custom hook that extends the functionality of the useAuth hook from react-oidc-context.
It adds the realm roles type to the user's profile in the auth object.
returns {ExtendedAuth} An extended auth object that includes realm roles in the profile object.

*/

export function useExtendedAuth() {
  const auth = useAuth() as ExtendedAuth

  return auth
}
