import { Outlet } from 'react-router-dom'
import { SideBar } from '@common/components/SideBar'
import StudyHeader from './StudyHeader/StudyHeader'
import StudyFooter from './StudyFooter/StudyFooter'
import { Box } from '@mui/material'
import { MainStyled } from '@common/components/layouts/DefaultLayout'
import { useUserGroup } from '@common/context/useUserGroup'
import eventEmitter from '@common/utils/eventEmitter'

export default function CreateOrUpdateStudyLayout() {
  const { currentUserGroup } = useUserGroup()

  const handleSaveAsDraft = () => {
    eventEmitter.emit('onSaveAsDraft')
  }

  const handleSaveAndContinue = () => {
    eventEmitter.emit('onSaveAndContinue')
  }

  return (
    <>
      <SideBar userGroup={currentUserGroup} />
      <StudyHeader
        isSidebarVisible={currentUserGroup === 'IAM'}
        handleSaveAsDraft={handleSaveAsDraft}
        isSubmitting={false}
      />
      <MainStyled isSidebarVisible={currentUserGroup === 'IAM'}>
        <Box sx={{ paddingTop: 8, paddingBottom: 12 }}>
          <Outlet />
        </Box>
      </MainStyled>
      <StudyFooter
        isSidebarVisible={currentUserGroup === 'IAM'}
        handleSaveAndContinue={handleSaveAndContinue}
        isSubmitting={false}
      />
    </>
  )
}
