export enum RouteKeyEnum {
  HOME = 'HOME',
  CREATE_STUDY = 'CREATE_STUDY',
  UPDATE_STUDY = 'UPDATE_STUDY',
  STUDY_DETAIL = 'STUDY_DETAIL',
  STUDY_OVERVIEW = 'STUDY_OVERVIEW',
  SITES = 'SITES',
  SUBJECTS = 'SUBJECTS',
  QC_TASKS = 'QC_TASKS',
  READER_TASKS = 'READER_TASKS',
  QUERIES = 'QUERIES',
  QC_DOCUMENTS = 'QC_DOCUMENTS',
  READER_DOCUMENTS = 'READER_DOCUMENTS',
  NOT_FOUND = 'NOT_FOUND',
}

export type RouteKey = RouteKeyEnum
export interface RouteConfig {
  path: string
  label: string
}

export const ROUTES: Record<RouteKey, RouteConfig> = {
  HOME: { path: '/', label: 'Home' },
  CREATE_STUDY: { path: '/create_study', label: 'Create Study' },
  UPDATE_STUDY: { path: '/studies/edit/:studyId', label: 'Update Study' },
  STUDY_DETAIL: { path: '/study_detail', label: 'Study Details' },
  QC_TASKS: { path: '/qc_tasks', label: 'QC Tasks' },
  STUDY_OVERVIEW: { path: '/study_overview', label: 'Study Overview' },
  SITES: { path: '/sites', label: 'Sites' },
  SUBJECTS: { path: '/subjects', label: 'Subjects' },
  QUERIES: { path: '/queries', label: 'Queries' },
  QC_DOCUMENTS: { path: '/qc_documents', label: 'QC Documents' },
  READER_TASKS: { path: '/reader_tasks', label: 'Reader Tasks' },
  READER_DOCUMENTS: { path: '/reader_documents', label: 'Reader Documents' },
  NOT_FOUND: { path: '*', label: 'Not Found' },
}
