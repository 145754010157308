import QcTaskListTableTaskManager from './QcTaskListTableTaskManager'
import QcTaskListTableMyTasks from './QcTaskListTableMyTasks'
import QcTaskListTableTaskPool from './QcTaskListTableTaskPool'
import QcTaskListTableCompletedTasks from './QcTaskListTableCompletedTasks'
import EnhancedTabs from '@common/components/Tab/EnhancedTabs'
import { useUserGroup } from '@common/context/useUserGroup'
import { useEffect } from 'react'
import getRoutes from '@qc_task_list_view/utils'

export default function QcTaskListTabs() {
  const { setCurrentUserGroup, setNavigationRoutes } = useUserGroup()

  useEffect(() => {
    setCurrentUserGroup('IQM')
    setNavigationRoutes(getRoutes())
  }, [])

  const tabs = [
    { label: 'My tasks', content: <QcTaskListTableMyTasks /> },
    { label: 'Task pool', content: <QcTaskListTableTaskPool /> },
    { label: 'Complete tasks', content: <QcTaskListTableCompletedTasks /> },
    {
      label: 'Task manager',
      content: <QcTaskListTableTaskManager />,
    },
  ]

  return <EnhancedTabs tabs={tabs} />
}
