import { ListStudy } from '@common/config/api/client'
import { Grid2 as Grid } from '@mui/material'
import StudyDetailsCard from '@study_setup/components/StudyDetails/StudyDetailsCard'
type StudyDetailsCardsProps = {
  layout: 'grid' | 'list'
  items: ListStudy[]
}
const StudyDetailsCards = ({ layout, items }: StudyDetailsCardsProps) => {
  return (
    <>
      <Grid container spacing={2} direction={layout === 'list' ? 'column' : 'row'} data-testid="studies-grid-container">
        {items?.map((item: ListStudy, index) => (
          <Grid size={layout === 'list' ? 12 : 4} data-testid="studies-grid-item" key={index}>
            <StudyDetailsCard item={item} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default StudyDetailsCards
