import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App.tsx'
import { AuthProvider } from 'react-oidc-context'
import { onSigninCallback, userManager } from './auth/oidc.config'
import { ProtectedApp } from './auth/components/ProtectedApp'
import { ThemeProvider } from '@common/theme'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from '@common/config/api/queryClient'
import { enableMocking } from './mock'
import { StepperProvider } from '@study_setup/contexts/stepper/provider.tsx'
import { CurrentUserGroupProvider } from '@common/context/CurrentUserGroupProvider.tsx'

const mountApp = () => {
  createRoot(document.getElementById('root')!).render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <AuthProvider userManager={userManager} onSigninCallback={onSigninCallback}>
          <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <BrowserRouter>
                <ProtectedApp>
                  <StepperProvider>
                    <CurrentUserGroupProvider>
                      <App />
                    </CurrentUserGroupProvider>
                  </StepperProvider>
                </ProtectedApp>
              </BrowserRouter>
            </LocalizationProvider>
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </StrictMode>,
  )
}
enableMocking().then(mountApp)
