import { LinkBehavior } from '@common/theme/overrides/components/LinkBehavior'

export const MuiLinkOverrides = () => ({
  MuiLink: {
    defaultProps: {
      underline: 'hover',
      component: LinkBehavior,
    },
  },
})
