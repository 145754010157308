import { Theme } from '@mui/material/styles'

export function InputOverrides(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: theme.spacing(1),
        },
      },
    },
  }
}
