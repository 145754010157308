import { ReactNode, useReducer } from 'react'
import { StepperContext, StepperContextType, StepType } from './context'
import { stepperReducer, initialStepperState, StepperActionType } from './reducer'

export const StepperProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(stepperReducer, initialStepperState)

  const setSteps = (steps: StepType[]) => {
    dispatch({ type: StepperActionType.SET_STEPS, payload: steps })
  }

  const setActiveStep = (step: number) => {
    const maxStepIndex = state.steps.length - 1
    const newStep = Math.min(Math.max(step, 0), maxStepIndex)
    dispatch({ type: StepperActionType.SET_ACTIVE_STEP, payload: newStep })
  }

  const markStepCompleted = (stepIndex: number) => {
    dispatch({ type: StepperActionType.MARK_STEP_COMPLETED, payload: stepIndex })
  }

  const markStepOptional = (stepIndex: number) => {
    dispatch({ type: StepperActionType.MARK_STEP_OPTIONAL, payload: stepIndex })
  }

  const resetSteps = () => {
    dispatch({ type: StepperActionType.RESET_STEPS })
  }

  const contextValue: StepperContextType = {
    ...state,
    setSteps,
    setActiveStep,
    markStepCompleted,
    markStepOptional,
    resetSteps,
  }

  return <StepperContext.Provider value={contextValue}>{children}</StepperContext.Provider>
}
