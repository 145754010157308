import { useMemo } from 'react'
import { TableBody, TableRow, TableCell } from '@mui/material'
import PopupMenuList from './PopupMenuList'
import { EnhancedTableBodyProps } from '../qc_task_list.types'
import TaskStateChip from './TaskStateChip'
import { formatTaskDate, getComparator } from '../utils'

export default function EnhancedTableBodyCompletedTasks({ rows, order, orderBy }: EnhancedTableBodyProps) {
  const visibleRows = useMemo(() => [...rows].sort(getComparator(order, orderBy)), [order, orderBy, rows])

  return (
    <TableBody>
      {visibleRows.map((row) => {
        return (
          <TableRow
            tabIndex={-1}
            key={row.task_id}
            sx={{
              borderBottom: '1px solid rgba(220, 227, 229, 1)',
            }}
          >
            <TableCell align="left" data-testid="task-pseudo-id-cell">
              {row.task_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="site-pseudo-id-cell">
              {row.site.site_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="subject-pseudo-id-cell">
              {row.site.site_pseudo_id} - {row.subject.subject_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="timepoint-name-cell">
              {row.timepoint.name}
            </TableCell>
            <TableCell align="left" data-testid="task-state-cell">
              <TaskStateChip taskState={row.task_state} />
            </TableCell>
            <TableCell align="left" data-testid="completed-date-cell">
              {formatTaskDate(row.completed_date ?? '')}
            </TableCell>
            <TableCell align="right" data-testid="actions-cell">
              <PopupMenuList />
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}
