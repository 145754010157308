import { Box, Tab, Tabs } from '@mui/material'
import { ReactElement, SyntheticEvent } from 'react'

type SegmentedControlsProps = {
  controls: {
    icon: ReactElement
  }[]
  value: number
  setValue: (val: number) => void
}

const SegmentedControls = ({ controls, value, setValue }: SegmentedControlsProps) => {
  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          bgcolor: 'secondary.main',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'center',
          padding: 0.5,
          gap: 0.5,
          typography: 'subtitle1',
        }}
      >
        <Tabs
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
          value={value}
          onChange={handleChange}
          aria-label="enhanced tabs"
        >
          {controls.map((control, index) => (
            <Tab
              key={index}
              sx={{
                borderRadius: 2,
                bgcolor: value === index ? 'background.default' : 'secondary.main',
                color: 'text.primary',
              }}
              icon={control.icon}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  )
}

export default SegmentedControls
